.my-modal {
  max-width: 100px;
  width: 100px;
}

.modal-ku {
  width: 750px;
  margin: auto;
}

icon-shape {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
}

.icon-sm {
  width: 100px;
  height: 100px;
}
